import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fblocks%2FPersonalInfo%2FpersonalInfo.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA51Ty46jMBC85yu8Gu3RI2DIA%2Be0l%2F0PgxvoibEtu5MwWs2%2Fr0wSIBmUXc2xq%2Byqcrf71RRQrbcJ%2B7Ni7IyKWsHSJPm5XzHWyZ7foDxPXB9BJ5VC0wiWsHUaoc%2FV60Uj%2FYfGZn2VqDX0PLQezUGwJCKlrA6Nt0ejBHup63rArFfguZcKj0Gwt0f%2FS6DRPVtybwGblob6dL7FuYHZbneRbKQbcoxab4OWwuC0%2FBCMn6E8IPHSDsdnJbcewZBgJ%2FCEldRzXqMBXmnZOcHySEiNjeEBdC1YIA9UtREm6InbE%2Fha27NgoDW6gCFSE9qiUmBmGfP7jLGnkwcSdEGwCgyBj%2FD7MRDWH7yyhobAEzU2NM1cz7Jk1pP0WjyMoiiKKz4fW%2Fpru%2F2dzRKuh4S2fIeKeI3RNL5nP4HOBiS0RjCybmZE1nENNX0Z%2FpUvLZHtuI9zfDgzum%2B%2B%2FoeR2w7cPL2xBiaDqX7e3%2BEjg1GLHZ7I0Xf3vxsydD%2FL7x5ULF0eh5dtFkbybJNGXbmkuxSgXDr4nc190K2ePCx%2BhbipdxfU01XPN65nP7Bz1pM0tP%2FmH7ylvyzE51%2FfmpnuKAUAAA%3D%3D%22%7D"
export var aboutAndProjectsWrapper = 'n9ec57a';
export var authorButton = 'n9ec577';
export var authorTextDescription = 'n9ec573';
export var authorWrapper = 'n9ec572';
export var blockWrapper = 'n9ec570';
export var labelStyle = 'n9ec574';
export var personalButtonWrapper = 'n9ec576';
export var personalImageStyle = 'n9ec575';
export var personalInfoWrapper = 'n9ec571';
export var scrollingTextStyles = 'n9ec579';
export var secondColumn = 'n9ec578';
export var skeletonIconStyle = 'n9ec57d';
export var smallBlockTypographyWrapper = 'n9ec57c';
export var smallBlockWrapper = 'n9ec57b';