import { FlexContainer } from "@/uikit/container";
import { Block } from "../base";
import { BlockContainer } from "../base/block-container";
import { RemoteImage } from "@/uikit/remote-image";
import {
    aboutAndProjectsWrapper,
    authorTextDescription,
    authorWrapper,
    blockWrapper,
    labelStyle,
    personalButtonWrapper,
    personalImageStyle,
    personalInfoWrapper,
    scrollingTextStyles,
    secondColumn,
    skeletonIconStyle,
    smallBlockTypographyWrapper,
    smallBlockWrapper
} from "./personalInfo.css";
import { Typography } from "@/uikit/Typography";
import IconButton from "@/svg/components/iconButton";

export const PersonalInfo = Block("aboutMe", ({ personalInfo, moreAboutMe, projects, scrollingText }) => {
    return (
        <BlockContainer id="personal-info" blockPaddingTop="xxl" layout="centeredColumn" >
            <FlexContainer gap="40" className={blockWrapper} justifyContent="center" alignItems="center">
                <FlexContainer direction="row" gap="24" alignItems="start" className={personalInfoWrapper}>
                    {personalInfo?.heroImage && (
                        <RemoteImage
                            image={personalInfo.heroImage}
                            width={224}
                            height={224}
                            className={personalImageStyle}
                            sizes={{
                                mobile: "100%",
                                tablet: "495px",
                                desktop: "495px",
                            }}
                        />
                    )}
                    <FlexContainer direction="column" alignItems="start" justifyContent="between" className={authorWrapper}>
                        <FlexContainer direction="column" alignItems="start" gap="small">
                            <Typography className={labelStyle} settings={{ tag: 'labelGrey', mode: 'light' }}>{personalInfo?.label}</Typography>
                            <Typography settings={{ tag: 'authorName', mode: 'black' }}>{personalInfo?.name}</Typography>
                            <Typography settings={{ tag: 'text', mode: 'grey' }} className={authorTextDescription}>{personalInfo?.description}</Typography>
                        </FlexContainer>
                        <FlexContainer alignItems="end" justifyContent="end" className={personalButtonWrapper}>
                            <IconButton />
                        </FlexContainer>
                    </FlexContainer>
                </FlexContainer>
                <FlexContainer direction="column" className={secondColumn}>
                    <FlexContainer direction="row" className={scrollingTextStyles}>
                        {scrollingText && scrollingText.map((part: any, index: any) => (
                            <span key={index}>{part.text}</span>
                        ))}
                    </FlexContainer>
                    <FlexContainer direction="row" className={aboutAndProjectsWrapper}>
                        <FlexContainer alignItems="start" direction="column" className={smallBlockWrapper}>
                            {moreAboutMe?.image && (
                                <RemoteImage
                                    image={moreAboutMe?.image}
                                    height={126}
                                    className={skeletonIconStyle}
                                    sizes={{
                                        mobile: "100%",
                                        tablet: "495px",
                                        desktop: "495px",
                                    }}
                                />
                            )}
                            <FlexContainer direction="row" justifyContent="between" className={smallBlockTypographyWrapper}>
                                <FlexContainer direction="column" gap="small">
                                    <Typography settings={{ tag: 'labelGrey', mode: 'grey' }}>{moreAboutMe?.label}</Typography>
                                    <Typography settings={{ tag: 'blockTitle', mode: 'black' }}>{moreAboutMe?.title}</Typography>
                                </FlexContainer>
                                <IconButton />
                            </FlexContainer>
                        </FlexContainer>
                        <FlexContainer alignItems="start" direction="column" className={smallBlockWrapper}>
                            {projects?.image && (
                                <RemoteImage
                                    image={projects?.image}
                                    height={126}
                                    className={skeletonIconStyle}
                                    sizes={{
                                        mobile: "100%",
                                        tablet: "495px",
                                        desktop: "495px",
                                    }}
                                />
                            )}
                            <FlexContainer direction="row" justifyContent="between" className={smallBlockTypographyWrapper}>
                                <FlexContainer direction="column" gap="small">
                                    <Typography settings={{ tag: 'labelGrey', mode: 'grey' }}>{projects?.label}</Typography>
                                    <Typography settings={{ tag: 'blockTitle', mode: 'black' }}>{projects?.title}</Typography>
                                </FlexContainer>
                                <IconButton />
                            </FlexContainer>
                        </FlexContainer>
                    </FlexContainer>
                </FlexContainer>
            </FlexContainer>
        </BlockContainer>
    )
});

export default PersonalInfo;